.pointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
.baseItem {
  max-width: 100%;
  overflow: hidden;
}
